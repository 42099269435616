<template>
    <v-card color="#F6F9FC" flat class="rounded-0 pt-6">
        <v-container>
            <v-row>
                <v-col cols="12" lg="3">
                    <DashboardSidebar></DashboardSidebar>
                </v-col>
                <v-col cols="12" lg="9">
                    <v-card flat class="pa-4">
                        <v-row align="center">
                            <v-col cols="12" lg="6" md="2">
                                <p class="mb-0 text-h6 font-weight-medium">{{ $t('消费记录') }}</p>
                            </v-col>
                            <v-col cols="12" lg="6" md="10">
                                <div class="d-flex align-center justify-end">
                                    <span>{{ $t('类型') }}：</span>
                                    <v-card flat height="40">
                                        <v-select :items="statusList" v-model="postParam.change_type" outlined
                                            dense @change="getlist"></v-select>
                                    </v-card>
                                </div>
                            </v-col>

                        </v-row>

                        <v-divider class="my-4"></v-divider>
                        <div>
                            <v-card color="#F2F2F2" class=" rounded-0 px-6 py-3" flat>
                                <v-row>
                                    <v-col cols="3">{{ $t('时间') }}</v-col>
                                    <v-col cols="3">{{ $t('类型') }}</v-col>
                                    <v-col cols="3">{{ $t('详情') }}</v-col>
                                    <v-col cols="3" class="text-right">{{ $t('金额') }}</v-col>
                                </v-row>

                            </v-card>
                            <div v-if="incomelist.length>0">
                                <v-card flat v-for="(item, idx) in incomelist" :key="idx" class="px-6 py-3"
                                :color="idx % 2 != 0 ? 'white' : '#F7F7F7'">
                                <v-row>
                                    <v-col cols="3">{{ item.createtime }}</v-col>
                                    <v-col cols="3">{{ item.clear_status }}收益</v-col>
                                    <v-col cols="3">{{ item.msg }}</v-col>
                                    <v-col cols="3" class="text-right warning--text">{{ item.amount }}</v-col>
                                </v-row>
                            </v-card>
                            <div v-if="pagearr.pagetotal > 0" class="text-center ma-4" align="center">
                                    <v-divider class="mb-6"/>
                                    <v-row align="center">
                                        <v-spacer />
                                        <v-pagination v-model.number="pagearr.page" :length="pagearr.pagetotal"
                                            total-visible="7" @input="getpagedata(1)" />      
                                    </v-row>
                                </div>
                            </div>
                            <v-card flat height="456" class="d-flex align-center justify-center flex-column" v-else>
                                    <img src="@/assets/images/loading/wusousuo.png" alt="">
                                    <p>{{ $t('无结果') }}</p>
                            </v-card>
                           
                        </div>
                    </v-card>



                </v-col>
            </v-row>
        </v-container>

    </v-card>
</template>
<script>
import DashboardSidebar from '../../components/DashboardSidebar.vue'
export default {
    components: {
        DashboardSidebar
    },
    data() {
        return {
            incomelist: [],
            statusList: [{
                text: this.$t('全部'),
                value: '-1',

            },
            {
                text: this.$t('支出'),
                value: '1',

            },
            {
                text: this.$t('收入'),
                value: '2',

            }
            ],
            postParam: {
                change_type: '-1',
                current_page: 1,
                page_number: 20
            },
            pagearr: {
                pageList: [10, 30, 50, 100],
                page: 1,
                pagenum: 20,
                pagetotal: 0,
                total: 0
            },

        }
    },
    mounted() {
        this.getlist();
    },
    methods: {
        getpagedata(type) {
            if (type === 2) {
                this.pagearr.page = 1;
                this.incomelist = [];
            }
            this.postParam.current_page = this.pagearr.page;
            this.postParam.page_number = this.pagearr.pagenum;
            this.getlist();
        },
        getlist() {
            this.$api.template.consume_list(this.postParam).then(res => {
                if (res.status) {
                    this.incomelist = res.data.records;
                    this.pagearr.total = parseInt(res.data.total);
                    this.pagearr.pagetotal = Math.ceil(
                        parseInt(res.data.total) / this.pagearr.pagenum
                    );
                }
            })
        }
    }
}
</script>
